export const countries = [
    {
      Code: "AD",
      Name: "Andorra",
    },
    {
      Code: "AE",
      Name: "United Arab Emirates",
    },
    {
      Code: "AF",
      Name: "Afghanistan",
    },
    {
      Code: "AG",
      Name: "Antigua and Barbuda",
    },
    {
      Code: "AI",
      Name: "Anguilla",
    },
    {
      Code: "AL",
      Name: "Albania",
    },
    {
      Code: "AM",
      Name: "Armenia",
    },
    {
      Code: "AN",
      Name: "Netherlands Antilles",
    },
    {
      Code: "AO",
      Name: "Angola",
    },
    {
      Code: "AQ",
      Name: "Antarctica",
    },
    {
      Code: "AR",
      Name: "Argentina",
    },
    {
      Code: "AS",
      Name: "American Samoa",
    },
    {
      Code: "AT",
      Name: "Austria",
    },
    {
      Code: "AU",
      Name: "Australia",
    },
    {
      Code: "AW",
      Name: "Aruba",
    },
    {
      Code: "AX",
      Name: "Aland Islands",
    },
    {
      Code: "AZ",
      Name: "Azerbaijan",
    },
    {
      Code: "BA",
      Name: "Bosnia and Herzegovina",
    },
    {
      Code: "BB",
      Name: "Barbados",
    },
    {
      Code: "BD",
      Name: "Bangladesh",
    },
    {
      Code: "BE",
      Name: "Belgium",
    },
    {
      Code: "BF",
      Name: "Burkina Faso",
    },
    {
      Code: "BG",
      Name: "Bulgaria",
    },
    {
      Code: "BH",
      Name: "Bahrain",
    },
    {
      Code: "BI",
      Name: "Burundi",
    },
    {
      Code: "BJ",
      Name: "Benin",
    },
    {
      Code: "BL",
      Name: "Saint Barthelemy",
    },
    {
      Code: "BM",
      Name: "Bermuda",
    },
    {
      Code: "BN",
      Name: "Brunei Darussalam",
    },
    {
      Code: "BO",
      Name: "Bolivia",
    },
    {
      Code: "BQ",
      Name: "Bonaire St. Eustatius",
    },
    {
      Code: "BR",
      Name: "Brazil",
    },
    {
      Code: "BS",
      Name: "Bahamas",
    },
    {
      Code: "BT",
      Name: "Bhutan",
    },
    {
      Code: "BV",
      Name: "Bouvet Island",
    },
    {
      Code: "BW",
      Name: "Botswana",
    },
    {
      Code: "BY",
      Name: "Belarus",
    },
    {
      Code: "BZ",
      Name: "Belize",
    },
    {
      Code: "CA",
      Name: "Canada",
    },
    {
      Code: "CC",
      Name: "Cocos (Keeling) Islands",
    },
    {
      Code: "CD",
      Name: "Congo",
    },
    {
      Code: "CF",
      Name: "Central African Republic",
    },
    {
      Code: "CG",
      Name: "Congo",
    },
    {
      Code: "CH",
      Name: "Switzerland",
    },
    {
      Code: "CI",
      Name: "Cote D'Ivoire (Ivory Coast)",
    },
    {
      Code: "CK",
      Name: "Cook Islands",
    },
    {
      Code: "CL",
      Name: "Chile",
    },
    {
      Code: "CM",
      Name: "Cameroon",
    },
    {
      Code: "CN",
      Name: "China",
    },
    {
      Code: "CO",
      Name: "Colombia",
    },
    {
      Code: "CR",
      Name: "Costa Rica",
    },
    {
      Code: "CU",
      Name: "Cuba",
    },
    {
      Code: "CV",
      Name: "Cape Verde",
    },
    {
      Code: "CW",
      Name: "Curacao",
    },
    {
      Code: "CX",
      Name: "Christmas Island",
    },
    {
      Code: "CY",
      Name: "Cyprus",
    },
    {
      Code: "CZ",
      Name: "Czech Republic",
    },
    {
      Code: "DE",
      Name: "Germany",
    },
    {
      Code: "DJ",
      Name: "Djibouti",
    },
    {
      Code: "DK",
      Name: "Denmark",
    },
    {
      Code: "DM",
      Name: "Dominica",
    },
    {
      Code: "DO",
      Name: "Dominican Republic",
    },
    {
      Code: "DZ",
      Name: "Algeria",
    },
    {
      Code: "EC",
      Name: "Ecuador",
    },
    {
      Code: "EE",
      Name: "Estonia",
    },
    {
      Code: "EG",
      Name: "Egypt",
    },
    {
      Code: "EH",
      Name: "Western Sahara",
    },
    {
      Code: "ER",
      Name: "Eritrea",
    },
    {
      Code: "ES",
      Name: "Spain",
    },
    {
      Code: "ET",
      Name: "Ethiopia",
    },
    {
      Code: "FI",
      Name: "Finland",
    },
    {
      Code: "FJ",
      Name: "Fiji",
    },
    {
      Code: "FK",
      Name: "Falkland Islands (Malvinas)",
    },
    {
      Code: "FM",
      Name: "Micronesia",
    },
    {
      Code: "FO",
      Name: "Faroe Islands",
    },
    {
      Code: "FR",
      Name: "France",
    },
    {
      Code: "GA",
      Name: "Gabon",
    },
    {
      Code: "GB",
      Name: "Great Britain (UK)",
    },
    {
      Code: "GD",
      Name: "Grenada",
    },
    {
      Code: "GE",
      Name: "Georgia",
    },
    {
      Code: "GF",
      Name: "French Guiana",
    },
    {
      Code: "GG",
      Name: "Guernsey",
    },
    {
      Code: "GH",
      Name: "Ghana",
    },
    {
      Code: "GI",
      Name: "Gibraltar",
    },
    {
      Code: "GL",
      Name: "Greenland",
    },
    {
      Code: "GM",
      Name: "Gambia",
    },
    {
      Code: "GN",
      Name: "Guinea",
    },
    {
      Code: "GP",
      Name: "Guadeloupe",
    },
    {
      Code: "GQ",
      Name: "Equatorial Guinea",
    },
    {
      Code: "GR",
      Name: "Greece",
    },
    {
      Code: "GS",
      Name: "S. Georgia and S. Sandwich Isls.",
    },
    {
      Code: "GT",
      Name: "Guatemala",
    },
    {
      Code: "GU",
      Name: "Guam",
    },
    {
      Code: "GW",
      Name: "Guinea-Bissau",
    },
    {
      Code: "GY",
      Name: "Guyana",
    },
    {
      Code: "HK",
      Name: "Hong Kong",
    },
    {
      Code: "HM",
      Name: "Heard and McDonald Islands",
    },
    {
      Code: "HN",
      Name: "Honduras",
    },
    {
      Code: "HR",
      Name: "Croatia (Hrvatska)",
    },
    {
      Code: "HT",
      Name: "Haiti",
    },
    {
      Code: "HU",
      Name: "Hungary",
    },
    {
      Code: "ID",
      Name: "Indonesia",
    },
    {
      Code: "IE",
      Name: "Ireland",
    },
    {
      Code: "IL",
      Name: "Israel",
    },
    {
      Code: "IM",
      Name: "Isle of Man",
    },
    {
      Code: "IN",
      Name: "India",
    },
    {
      Code: "IO",
      Name: "British Indian Ocean Territory",
    },
    {
      Code: "IQ",
      Name: "Iraq",
    },
    {
      Code: "IR",
      Name: "Iran",
    },
    {
      Code: "IS",
      Name: "Iceland",
    },
    {
      Code: "IT",
      Name: "Italy",
    },
    {
      Code: "JE",
      Name: "Jersey",
    },
    {
      Code: "JM",
      Name: "Jamaica",
    },
    {
      Code: "JO",
      Name: "Jordan",
    },
    {
      Code: "JP",
      Name: "Japan",
    },
    {
      Code: "KE",
      Name: "Kenya",
    },
    {
      Code: "KG",
      Name: "Kyrgyzstan",
    },
    {
      Code: "KH",
      Name: "Cambodia",
    },
    {
      Code: "KI",
      Name: "Kiribati",
    },
    {
      Code: "KM",
      Name: "Comoros",
    },
    {
      Code: "KN",
      Name: "Saint Kitts and Nevis",
    },
    {
      Code: "KP",
      Name: "Korea (North)",
    },
    {
      Code: "KR",
      Name: "Korea (South)",
    },
    {
      Code: "KW",
      Name: "Kuwait",
    },
    {
      Code: "KY",
      Name: "Cayman Islands",
    },
    {
      Code: "KZ",
      Name: "Kazakhstan",
    },
    {
      Code: "LA",
      Name: "Laos",
    },
    {
      Code: "LB",
      Name: "Lebanon",
    },
    {
      Code: "LC",
      Name: "Saint Lucia",
    },
    {
      Code: "LI",
      Name: "Liechtenstein",
    },
    {
      Code: "LK",
      Name: "Sri Lanka",
    },
    {
      Code: "LR",
      Name: "Liberia",
    },
    {
      Code: "LS",
      Name: "Lesotho",
    },
    {
      Code: "LT",
      Name: "Lithuania",
    },
    {
      Code: "LU",
      Name: "Luxembourg",
    },
    {
      Code: "LV",
      Name: "Latvia",
    },
    {
      Code: "LY",
      Name: "Libya",
    },
    {
      Code: "MA",
      Name: "Morocco",
    },
    {
      Code: "MC",
      Name: "Monaco",
    },
    {
      Code: "MD",
      Name: "Moldova",
    },
    {
      Code: "ME",
      Name: "Montenegro",
    },
    {
      Code: "MF",
      Name: "Saint Martin",
    },
    {
      Code: "MG",
      Name: "Madagascar",
    },
    {
      Code: "MH",
      Name: "Marshall Islands",
    },
    {
      Code: "MK",
      Name: "F.Y.R.O.M. (Macedonia)",
    },
    {
      Code: "ML",
      Name: "Mali",
    },
    {
      Code: "MM",
      Name: "Myanmar",
    },
    {
      Code: "MN",
      Name: "Mongolia",
    },
    {
      Code: "MO",
      Name: "Macau",
    },
    {
      Code: "MP",
      Name: "Northern Mariana Islands",
    },
    {
      Code: "MQ",
      Name: "Martinique",
    },
    {
      Code: "MR",
      Name: "Mauritania",
    },
    {
      Code: "MS",
      Name: "Montserrat",
    },
    {
      Code: "MT",
      Name: "Malta",
    },
    {
      Code: "MU",
      Name: "Mauritius",
    },
    {
      Code: "MV",
      Name: "Maldives",
    },
    {
      Code: "MW",
      Name: "Malawi",
    },
    {
      Code: "MX",
      Name: "Mexico",
    },
    {
      Code: "MY",
      Name: "Malaysia",
    },
    {
      Code: "MZ",
      Name: "Mozambique",
    },
    {
      Code: "NA",
      Name: "Namibia",
    },
    {
      Code: "NC",
      Name: "New Caledonia",
    },
    {
      Code: "NE",
      Name: "Niger",
    },
    {
      Code: "NF",
      Name: "Norfolk Island",
    },
    {
      Code: "NG",
      Name: "Nigeria",
    },
    {
      Code: "NI",
      Name: "Nicaragua",
    },
    {
      Code: "NL",
      Name: "Netherlands",
    },
    {
      Code: "NO",
      Name: "Norway",
    },
    {
      Code: "NP",
      Name: "Nepal",
    },
    {
      Code: "NR",
      Name: "Nauru",
    },
    {
      Code: "NU",
      Name: "Niue",
    },
    {
      Code: "NZ",
      Name: "New Zealand (Aotearoa)",
    },
    {
      Code: "OM",
      Name: "Oman",
    },
    {
      Code: "PA",
      Name: "Panama",
    },
    {
      Code: "PE",
      Name: "Peru",
    },
    {
      Code: "PF",
      Name: "French Polynesia",
    },
    {
      Code: "PG",
      Name: "Papua New Guinea",
    },
    {
      Code: "PH",
      Name: "Philippines",
    },
    {
      Code: "PK",
      Name: "Pakistan",
    },
    {
      Code: "PL",
      Name: "Poland",
    },
    {
      Code: "PM",
      Name: "St. Pierre and Miquelon",
    },
    {
      Code: "PN",
      Name: "Pitcairn",
    },
    {
      Code: "PR",
      Name: "Puerto Rico",
    },
    {
      Code: "PS",
      Name: "Palestinian Territory, Occupied",
    },
    {
      Code: "PT",
      Name: "Portugal",
    },
    {
      Code: "PW",
      Name: "Palau",
    },
    {
      Code: "PY",
      Name: "Paraguay",
    },
    {
      Code: "QA",
      Name: "Qatar",
    },
    {
      Code: "RE",
      Name: "Reunion",
    },
    {
      Code: "RO",
      Name: "Romania",
    },
    {
      Code: "RS",
      Name: "Serbia",
    },
    {
      Code: "RU",
      Name: "Russian Federation",
    },
    {
      Code: "RW",
      Name: "Rwanda",
    },
    {
      Code: "SA",
      Name: "Saudi Arabia",
    },
    {
      Code: "SB",
      Name: "Solomon Islands",
    },
    {
      Code: "SC",
      Name: "Seychelles",
    },
    {
      Code: "SD",
      Name: "Sudan",
    },
    {
      Code: "SE",
      Name: "Sweden",
    },
    {
      Code: "SG",
      Name: "Singapore",
    },
    {
      Code: "SH",
      Name: "St. Helena Island",
    },
    {
      Code: "SI",
      Name: "Slovenia",
    },
    {
      Code: "SJ",
      Name: "Svalbard and Jan Mayen",
    },
    {
      Code: "SK",
      Name: "Slovakia",
    },
    {
      Code: "SL",
      Name: "Sierra Leone",
    },
    {
      Code: "SM",
      Name: "San Marino",
    },
    {
      Code: "SN",
      Name: "Senegal",
    },
    {
      Code: "SO",
      Name: "Somalia",
    },
    {
      Code: "SR",
      Name: "Suriname",
    },
    {
      Code: "SS",
      Name: "South Sudan",
    },
    {
      Code: "ST",
      Name: "Sao Tome and Principe",
    },
    {
      Code: "SV",
      Name: "El Salvador",
    },
    {
      Code: "SX",
      Name: "Sint Maarten",
    },
    {
      Code: "SY",
      Name: "Syrian",
    },
    {
      Code: "SZ",
      Name: "Swaziland",
    },
    {
      Code: "TC",
      Name: "Turks and Caicos Islands",
    },
    {
      Code: "TD",
      Name: "Chad",
    },
    {
      Code: "TF",
      Name: "French Southern Territories",
    },
    {
      Code: "TG",
      Name: "TOGO",
    },
    {
      Code: "TH",
      Name: "Thailand",
    },
    {
      Code: "TJ",
      Name: "Tajikistan",
    },
    {
      Code: "TK",
      Name: "Tokelau",
    },
    {
      Code: "TL",
      Name: "Timor-Leste",
    },
    {
      Code: "TM",
      Name: "Turkmenistan",
    },
    {
      Code: "TN",
      Name: "Tunisia",
    },
    {
      Code: "TO",
      Name: "Tonga",
    },
    {
      Code: "TR",
      Name: "Turkey",
    },
    {
      Code: "TT",
      Name: "Trinidad and Tobago",
    },
    {
      Code: "TV",
      Name: "Tuvalu",
    },
    {
      Code: "TW",
      Name: "Taiwan",
    },
    {
      Code: "TZ",
      Name: "Tanzania-United Republic",
    },
    {
      Code: "UA",
      Name: "Ukraine",
    },
    {
      Code: "UG",
      Name: "Uganda",
    },
    {
      Code: "UM",
      Name: "Midway Islands",
    },
    {
      Code: "US",
      Name: "United States",
    },
    {
      Code: "UY",
      Name: "Uruguay",
    },
    {
      Code: "UZ",
      Name: "Uzbekistan",
    },
    {
      Code: "VA",
      Name: "Vatican",
    },
    {
      Code: "VC",
      Name: "Vincent",
    },
    {
      Code: "VE",
      Name: "Venezuela",
    },
    {
      Code: "VG",
      Name: "Virgin Islands-British",
    },
    {
      Code: "VI",
      Name: "Virgin Islands-US",
    },
    {
      Code: "VN",
      Name: "Vietnam",
    },
    {
      Code: "VU",
      Name: "Vanuatu",
    },
    {
      Code: "WF",
      Name: "Wallis and Futuna Islands",
    },
    {
      Code: "WS",
      Name: "Samoa",
    },
    {
      Code: "XC",
      Name: "Crimea",
    },
    {
      Code: "YE",
      Name: "Yemen",
    },
    {
      Code: "YT",
      Name: "Mayotte",
    },
    {
      Code: "ZA",
      Name: "South Africa",
    },
    {
      Code: "ZM",
      Name: "Zambia",
    },
    {
      Code: "ZW",
      Name: "Zimbabwe",
    },
  ];
  