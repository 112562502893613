import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./Forget.css";
import { toast } from "react-toastify";
import Yatra from '../Navbar/yatri-service.png'

const ForgetPassword = () => {
  const { token, email } = useParams();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    const payload = {
      Email: email,
      password: newPassword,
      password_confirmation: confirmPassword,
      token,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        "https://admin.yatriservice.com/api/Agent/ResetPassword",
        payload
      );
      if(response.data.success === true){
        setSuccessMessage(response.data.message || "Password reset successfully!");
        toast.success(response.data.message)
        navigate('/')
      }
      setSuccessMessage(response.data.message || "Password reset successfully!");
     console.log(response.data)
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-vh-100 position-relative forget_password_main_container">
      <div
        className="bg-dark position-absolute top-0 start-0 w-100 h-100"
        style={{ opacity: 0.6 }}
      />
      <Container
        className="d-flex align-items-center justify-content-center h-100 position-relative"
        style={{ paddingTop: "100px" }}
      >
        <div
          className="form-layout_forget bg-dark text-white p-4 rounded shadow form-layout w-100"
          style={{ maxWidth: "400px" }}
        >
          <div className="text-center mb-4">
          <Link
              to="/"
              className="d-flex align-items-center gap-2 justify-content-center text-decoration-none text-white"
            >
              <img
                src={Yatra}
                alt="Logo"
                style={{ width: "150px" }}
              />
          </Link>
          </div>
          <Form onSubmit={handleResetPassword}>
            <h2 className="h5 text-center mb-3">Forgot Password</h2>

            {errorMessage && (
              <div className="alert alert-danger text-center">{errorMessage}</div>
            )}
            {successMessage && (
              <div className="alert alert-success text-center">{successMessage}</div>
            )}

            <Form.Group className="mb-4" controlId="newPassword">
              <Form.Label className="forget_password_new">New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                className="border-0 forget_password_inputs"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-5" controlId="confirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm password"
                className="border-0"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Form.Group>

            <div className="text-center mt-3">
              <Button type="submit" variant="primary" className="w-100" disabled={loading}>
                {loading ? "Resetting..." : "Reset"}
              </Button>
            </div>
            <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
              <p className="mb-0">Remember it?</p>
            <Link to="/agent-registration" className="text-primary text-decoration-none">
                Sign in
            </Link>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ForgetPassword;
