import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import axios from "axios";
import "./Profile.css";
import { toast } from "react-toastify";

const UpdateProfileModal = ({ show, onHide, datas }) => {
  const [formData, setFormData] = useState({
    name: datas.first_name,
    agencyName: datas.company_name,
    aadhaarNo: datas.aadhaar_card,
    gstNo: datas.gst_no,
    phone: datas.phone,
    email: datas.email,
    address: datas.address,
    state: datas.state,
    country: datas.country,
    city: datas.city,
    zip: datas.zip,
    ProfileImg: null, // To handle file upload
  });

  // Update formData with new walletData when it changes
  useEffect(() => {
    setFormData({
      name: datas.first_name,
      agencyName: datas.company_name,
      aadhaarNo: datas.aadhaar_card,
      gstNo: datas.gst_no,
      phone: datas.phone,
      email: datas.email,
      address: datas.address,
      state: datas.state,
      country: datas.country,
      city: datas.city,
      zip: datas.zip,
      ProfileImg: null,
    });
  }, [datas]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, ProfileImg: file }));
  };

  // Submit the updated profile data
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare formData for submission
    const updatedData = new FormData();
    updatedData.append("Name", formData.name);
    updatedData.append("AgencyName", formData.agencyName);
    updatedData.append("AadhaarNo", formData.aadhaarNo);
    updatedData.append("GstNo", formData.gstNo);
    updatedData.append("Phone", formData.phone);
    updatedData.append("Email", formData.email);
    updatedData.append("Address", formData.address);
    updatedData.append("State", formData.state);
    updatedData.append("Country", formData.country);
    updatedData.append("City", formData.city);
    updatedData.append("Zip", formData.zip);
    if (formData.ProfileImg) {
      updatedData.append("CompanyLogo", formData.ProfileImg);
    }

    try {
      const response = await axios.post(
        "https://admin.yatriservice.com/api/Agent/Update",
        updatedData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (response.status === 200) {
        // Handle successful update if needed
        window.location.reload()
       toast.success("Agent Profile Updated")
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile")
    }

    onHide(); // Close the modal
  };

  return (
    <div className="updated_modal_profile">
      <Modal show={show} onHide={onHide} centered className="updated_modal_profile">
        <Modal.Header closeButton>
          <Modal.Title>Update Company Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Agency Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="agencyName"
                    value={formData.agencyName}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

            
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Aadhaar Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="aadhaarNo"
                    value={formData.aadhaarNo}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>GST Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="gstNo"
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

             
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

            
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

             
            </Row>

            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>

            
            </Row>
            <Row>
            <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip"
                    value={formData.zip}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Company Logo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
            </Col>
            </Row>
            <Button variant="primary" type="submit" className="w-100" style={{background:'#161845'}}>
              <FaSave /> Save Changes
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateProfileModal;
