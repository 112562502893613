import React, { useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { IoCall } from "react-icons/io5";
import { FaEnvelopeOpenText } from "react-icons/fa";
import "./HeroNavbar.css";
import { Link, useNavigate } from "react-router-dom";
import Yatra from "./yatri-service.png";

import { useDispatch } from "react-redux";
import { Wallentbalance, login } from "../../../redux/services/operations/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
const HeroNavbar = () => {
  const [LoginId, setLoginId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acceptCookie, setAcceptCookie] = useState(true);
  const [UserName, setUserName] = useState("guruansh@holidaychacha.com");
  const [Password, setPassword] = useState("");
  const [Passwordss, setPasswordss] = useState("Hello123@123");
  const [APIKey, setAPIKey] = useState("8FFA90A1-0459-43EC-BDA8-B77D3BCA57A7");
  const [email, setEmail] = useState("");
  const [loadings, setLoadings] = useState(false); // To manage the loading state
  const handleLogin = async () => {
    dispatch(login(LoginId, Password, APIKey, navigate));
    dispatch(Wallentbalance(LoginId, Password));
    // navigate('/agent/dashboard')
  };
  const [responseMessage, setResponseMessage] = useState(null); // To display API responses
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoadings(true);

    try {
      const response = await axios.post(
        "https://admin.yatriservice.com/api/Agent/ForgetPassword",
        { Email: email }
      );
      if(response.data.success === true){
        toast.success(response.data.message)
        handleModalClose()
      }
      setResponseMessage(response.data.message || "Request submitted successfully.");
    } catch (error) {
      setResponseMessage(error.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      setLoadings(false);
    }
  };
  return (
    <header className="header">
      <div className="yscontainerfull">
        <div
          id="cookiesDiv"
          className={`${acceptCookie ? "d-block" : "d-none"} cookiesdiv`}
          style={{}}
        >
          {/* Text to Show in cookie*/}
          <span>
            YatraService uses necessary cookies to improve your user experience
            and to make our website, mobile applications and services work.
          </span>
          <button
            type="button"
            className="cookiesbtn"
            onClick={() => setAcceptCookie(false)}
          >
            Accept
          </button>
        </div>
      </div>
      <div style={{ background: "#fff" }}>
        <Container>
          <Row>
            <Col md={2} style={{ textAlign: "center", marginTop: "5px" }}>
              <Link to="/">
                <img src={Yatra} alt="" width={150} />
              </Link>
            </Col>
            <Col md={10}>
              <div
                className="new_loginbox hero-login-box-navbar"
                
              >
                <div className="new_loginform forgot_paas_link">
                  <div id="ShowCredentials" className="tbofleft navbar_again_yatri">
                    <input
                      name="LoginName"
                      type="text"
                      id="LoginName"
                      className="new__loginput new_username"
                      placeholder="Username"
                      value={LoginId}
                      onChange={(e) => setLoginId(e.target.value)}
                      style={{marginTop:"-19px"}}
                    />
                   <div className="again_yatri_nav">
                   <input
                      name="Password"
                      type="password"
                      id="Password"
                      className="new__loginput new_pass"
                      placeholder="Password"
                      // aria-label="Login Password"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                    />  <div>
                    <button
                      type="button"
                      className="btn btn-link p-0"
                      onClick={handleModalShow}
                      style={{fontSize:'12px',color:'#161845'}}
                    >
                      Forget Password
                    </button>
                  </div>
                   </div>
                  </div>
                </div>
                <div className="new_loginbtn">
                  <button
                    type="submit"
                    name="Login"
                    placeholder="Login"
                    value="Login"
                    defaultValue="Login"
                    id="Login"
                    className="new_frmbtn"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                  <Link
                    to="/agent-registration"
                    className="new_frmbtn new__registerbtn"
                  >
                    Register Now
                  </Link>
                  <br />
                  <input
                    id="captchaLoginName"
                    name="captchaLoginName"
                    type="hidden"
                    defaultValue=""
                  />
                </div>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {responseMessage ? (
            <p className="text-center">{responseMessage}</p>
          ) : ( */}
            <Form onSubmit={handleForgotPassword}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Enter your email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100" disabled={loadings}>
                {loadings ? "Submitting..." : "Submit"}
              </Button>
            </Form>
          {/* )} */}
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default HeroNavbar;
