import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  FaUser,
  FaUsers,
  FaBuilding,
  FaPhoneAlt,
  FaLock,
  FaEnvelope,
  FaMapMarkerAlt,
  FaIdCard,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import "./RegisterationForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { CountriesArray } from "../../../../dashboard/Flight/FlightSearchMobile/Countries";
import { CountriesArray } from "./Countries";
import { IoNewspaperOutline } from "react-icons/io5";
import { TfiWrite } from "react-icons/tfi";

const RegisterationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Name: "",
    AgencyName: "",
    AadhaarNo: "",
    Phone: "",
    Password: "",
    GstNo: "",
    confirmPassword: "",
    Email: "",
    Address: "",
    Country: "India",
    State: "",
    // City: "",
    Zip: "",
  });
  const [registerType, setRegisterType] = useState("Proprietor");

  const handleFirmTypeClick = (e) => {
    const firmTypes = document.getElementsByclassNameName("firm_type");
    for (let i = 0; i < firmTypes.length; i++) {
      firmTypes[i].children[0].className = "";
    }
    e.target.className = "active";
    setRegisterType(e.target.innerText);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isPasswordValid()) {
      alert("Please enter a valid password.");
      return;
    }

    if (formData.Password !== formData.confirmPassword) {
      alert("Passwords do not match.");
      return;
    } else {
      const response = await axios.post(
        "https://admin.yatriservice.com/api/Agent/Create",
        formData
      );
      if (response) {
        navigate("/");
        console.log("sucessfull");
        setFormData({
          Name: "",
          Phone: "",
          AadhaarNo: "",
          Password: "",
          confirmPassword: "",
          AgencyName: "",
          Email: "",
          GstNo: "",
          Address: "",
          Country: "",
          State: "",

          // City: "",
          Zip: "",
        });
      } else {
        console.log("error");
      }
      // Handle form submission logic here
    }
  };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showPass, setShowPass] = useState(true);
  // const handlePasswordChange = (e) => {
  //   setPassword(e.target.value);
  // };

  // const handleConfirmPasswordChange = (e) => {
  //   setConfirmPassword(e.target.value);
  // };
  const handleShowPass = () => {
    setShowPass(!showPass);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordValid = () => {
    const passwordRegex =
      /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/;
    return passwordRegex.test(password) || password === "";
  };

  const isConfirmPasswordValid = () => {
    return confirmPassword === password || confirmPassword === "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="agent_reg_page">
      <Container style={{ width: "80%" }}>
        <div className="inner_reg_form" style={{ paddingInline: "40px" }}>
          <Row className="mb-sm-10">
            <Col md={12}>
              <div className="form_title">
                <h3>
                  Begin with{" "}
                  <span style={{ color: "#2d3290", fontSize: "40px" }}>
                    Yatri Service
                  </span>
                  : Unlock B2B Flight Deals
                </h3>
              </div>
            </Col>
          </Row>

          <Form className="reg_form_sec" onSubmit={handleSubmit}>
            <input type="hidden" name="firmtype" value="PROPRIETOR" />
            <div className="form_heading">
              <h5>Account Information</h5>
            </div>

            <Row>
              <Col md={6}>
                <div className="form-group form_field">
                  <input
                    required
                    type="text"
                    name="Name"
                    id="Name"
                    className="form-control"
                    placeholder="Agency Name"
                    value={formData.Name}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaUser />
                  </div>
                  <p className="form_error name" style={{ display: "none" }}>
                    <span>Enter Agency Name</span>
                  </p>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group form_field">
                  <input
                    required
                    type="text"
                    name="AgencyName"
                    id="AgencyName"
                    className="form-control"
                    placeholder="Owner Name"
                    value={formData.AgencyName}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaUser />
                  </div>
                  <p className="form_error name" style={{ display: "none" }}>
                    <span>Enter Owner Name</span>
                  </p>
                </div>
              </Col>

              <Col md={6}>
                {/* <div className="form-group form_field phone_field"> */}
                <div className="form-group form_field ">
                  {/* <span className="phncode" id="mobileCode">
                    +93
                  </span> */}
                  <input
                    required
                    data-valid="required"
                    type="tel"
                    name="Phone"
                    className="form-control"
                    id="otpNumber"
                    placeholder="Mobile Number"
                    maxLength="10"
                    value={formData.Phone}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaPhoneAlt />
                  </div>

                  <span id="mobile_successMsg" className="note_msg">
                    An OTP will be sent on your above entered number for
                    verification.
                  </span>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group form_field password_field">
                  <input
                    autoComplete="new-password"
                    required
                    type="text"
                    name="AadhaarNo"
                    id="AadhaarNo"
                    value={formData.AadhaarNo}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Adhaar Number"
                  />
                  <div className="form_icon">
                    <TfiWrite />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group form_field password_field">
                  <input
                    autoComplete="new-password"
                    required
                    type="text"
                    name="GstNo"
                    id="GstNo"
                    value={formData.GstNo}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Enter GST Number"
                  />
                  <div className="form_icon">
                    <IoNewspaperOutline />
                  </div>
                  {/* <div
                    className="pass_eyeswitch passicon"
                    onClick={handleShowPassword}
                  >
                    <FaEyeSlash
                      className={`toggle_pwd ${
                        showPassword ? "active" : "d-none"
                      }`}
                    />
                    <FaEye
                      className={`toggle_pwd ${
                        !showPassword ? "active" : "d-none"
                      }`}
                    />
                  </div> */}

                  {/* <span id="pass_note" className="note_msg passmsg">
                    Password must have at least 8 characters, 1 upper case, 1
                    lower case, 1 special character, 1 number
                  </span> */}
                  {/* {!isPasswordValid() && (
                    <p
                      className="form_error confirm-password"
                      style={{ display: "block" }}
                    >
                      <span>Please enter a valid password. </span>
                    </p>
                  )} */}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group form_field">
                  <input
                    data-valid="required"
                    type="email"
                    name="Email"
                    className="form-control"
                    id="otpEmail"
                    placeholder="Email Address"
                    value={formData.Email}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaEnvelope />
                  </div>
                  <p
                    className="form_error email"
                    id="otp_mailError"
                    style={{ display: "none" }}
                  >
                    <span></span>
                  </p>
                  <span id="email_successMsg" className="note_msg">
                    An OTP will be sent on your above entered email for
                    verification.
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group form_field password_field">
                  <input
                    autoComplete="new-password"
                    required
                    type={showPassword ? "password" : "text"}
                    name="Password"
                    id="Password"
                    value={formData.Password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password"
                  />
                  <div className="form_icon">
                    <FaLock />
                  </div>
                  <div
                    className="pass_eyeswitch passicon"
                    onClick={handleShowPassword}
                  >
                    <FaEyeSlash
                      className={`toggle_pwd ${
                        showPassword ? "active" : "d-none"
                      }`}
                    />
                    <FaEye
                      className={`toggle_pwd ${
                        !showPassword ? "active" : "d-none"
                      }`}
                    />
                  </div>

                  <p
                    className="form_error password"
                    style={{ display: "none" }}
                  >
                    <span>Enter Password</span>
                  </p>
                  <span id="pass_note" className="note_msg passmsg">
                    Password must have at least 8 characters, 1 upper case, 1
                    lower case, 1 special character, 1 number
                  </span>
                  {!isPasswordValid() && (
                    <p
                      className="form_error confirm-password"
                      style={{ display: "block" }}
                    >
                      <span>Please enter a valid password. </span>
                    </p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group form_field password_field">
                  <input
                    data-valid="required"
                    id="confirm-password"
                    type={showPass ? "password" : "text"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                  <div className="form_icon">
                    <FaLock />
                  </div>
                  <div
                    className="pass_eyeswitch crmpassicon"
                    onClick={handleShowPass}
                  >
                    <FaEyeSlash
                      className={`toggle_pwd ${showPass ? "active" : "d-none"}`}
                    />
                    <FaEye
                      className={`toggle_pwd ${
                        !showPass ? "active" : "d-none"
                      }`}
                    />
                  </div>
                  <p
                    className="form_error confirm-password"
                    id="cPassword"
                    style={{ display: "none" }}
                  >
                    <span>Password doesn't match</span>
                  </p>
                  {!isConfirmPasswordValid() && (
                    <p
                      className="form_error confirm-password"
                      style={{ display: "block" }}
                    >
                      <span>Passwords do not match</span>
                    </p>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="form-group form_field">
                  <input
                    type="text"
                    name="Address"
                    id="Address"
                    className="form-control"
                    placeholder="Address"
                    value={formData.Address}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaMapMarkerAlt />
                  </div>
                  <p className="form_error address" style={{ display: "none" }}>
                    <span>Enter Address</span>
                  </p>
                  <span className="note_msg">
                    Please enter full address or else application will be
                    rejected.
                  </span>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group form_field">
                  <select
                    className="form-control select2"
                    name="Country"
                    id="Country"
                    data-valid="required"
                    value={formData.Country}
                    onChange={handleChange}
                  >
                    <option disabled>Select Country</option>
                    {CountriesArray.map((country) => (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  <div className="form_icon">
                    <FaMapMarkerAlt />
                  </div>
                  <p className="form_error country" style={{ display: "none" }}>
                    <span>Enter Country</span>
                  </p>
                </div>
              </Col>

              <Col md={6} id="state_div">
                <div className="form-group form_field">
                  <input
                    data-valid="required"
                    type="text"
                    id="State"
                    name="State"
                    className="form-control state_input"
                    placeholder="State"
                    style={{ display: "none" }}
                  />
                  <select
                    className="form-control select2 state_select"
                    name="State"
                    id="State"
                    data-valid="required"
                    data-url="https://heritage.travelsdata.com"
                    value={formData.State}
                    onChange={handleChange}
                  >
                    <option value="Delhi">Delhi</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">
                      Dadra and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kenmore">Kenmore</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Narora">Narora</option>
                    <option value="Natwar">Natwar</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Paschim Medinipur">Paschim Medinipur</option>
                    <option value="Pondicherry">Pondicherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="Vaishali">Vaishali</option>
                    <option value="West Bengal">West Bengal</option>
                  </select>
                  <div className="form_icon">
                    <FaMapMarkerAlt />
                  </div>
                  <p className="form_error state" style={{ display: "none" }}>
                    <span>Enter State</span>
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group form_field">
                  <input
                    data-valid="required"
                    id="pincode"
                    type="text"
                    name="Zip"
                    className="form-control"
                    placeholder="Pin code"
                    value={formData.Zip}
                    onChange={handleChange}
                  />
                  <div className="form_icon">
                    <FaUser />
                  </div>
                  <p className="form_error pincode" style={{ display: "none" }}>
                    <span>Enter Pincode</span>
                  </p>
                </div>
              </Col>
            </Row>
            <Row></Row>

            <div className="form_heading">
              <h5>Agency licence copy</h5>
            </div>
            <Row>
              <Col md={6}>
                <div className="form-group form_field">
                  <input type="file" className="form-control" />
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <Col md={4}>
                <Button
                  type="submit"
                  className="reguserbtn"
                  style={{ marginBottom: "10px" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default RegisterationForm;
