import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Button,
  Form,
} from "react-bootstrap";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import "./NewMarkup.css";
import { useSelector } from "react-redux";

const NewMarkup = () => {
  const [serviceType, setServiceType] = useState("");
  const [amount, setAmount] = useState("");
  const [amountType, setAmountType] = useState("");
  const navigate = useNavigate();
  const {walletData}=useSelector((state)=>state.auth)
  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const handleAmountTypeChange = (e) => {
    setAmountType(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const newMarkupData = {
      AgentId: walletData.Id,
      api: "0",
      flight_type: serviceType, // Use selected flight type from form
      service_type: amountType, // Use selected amount type from form
      service_fee: amount, // Use inputted amount from form
      user_type: "b2b", // This can be dynamic if needed
      markup_type: "all", // This can be dynamic if needed
    };

    try {
      const response = await axios.post(
        "https://admin.yatriservice.com/api/agent-markup/create",
        newMarkupData
      );
      console.log("Response data:", response.data);

      // Optionally navigate to a different page after success
      navigate("/agent/mark-up");
    } catch (error) {
      console.error("Error posting markup data:", error);
      // Handle the error appropriately in your UI (e.g., show a message to the user)
    }
  };

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row  style={{background:'#f09bff0d',padding:'0px 4px'}}>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="" style={{fontSize:'24px',marginTop:'15px',fontWeight:'600',textTransform:'uppercase'}}>Manage Markup</h1>
              </Col>
              <Col sm={6} style={{ marginTop: "10px" }}>
                          <Form.Group
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <Link
                              to="/agent/mark-up"
                              className="btn btn-theme-sec"
                              style={{ marginRight: "5px" }}
                            >
                              <FaArrowLeft /> Back
                            </Link>
                            <Button className="btn btn-theme" type="submit">
                              <FaSave /> Save Markup
                            </Button>
                          </Form.Group>
                        </Col>
            </Row>
          </Container>
        </div>
        <section className="content" style={{border:'1px solid #efeded'}}>
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  {/* <Card.Header>
                    <h3 className="card-title" style={{ color: "#fff" }}>
                      New Markup
                    </h3>
                  </Card.Header> */}
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        {/* <Col sm={12} style={{ marginTop: "10px" }}>
                          <Form.Group
                            className="form-group"
                            style={{ textAlign: "right" }}
                          >
                            <Link
                              to="/agent/mark-up"
                              className="btn btn-theme-sec"
                              style={{ marginRight: "5px" }}
                            >
                              <FaArrowLeft /> Back
                            </Link>
                            <Button className="btn btn-theme" type="submit">
                              <FaSave /> Save Markup
                            </Button>
                          </Form.Group>
                        </Col> */}

                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="amount"
                              value={amount}
                              onChange={handleAmountChange}
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Amount Type
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="amount_type"
                              value={amountType}
                              onChange={handleAmountTypeChange}
                              required
                            >
                              <option value="">Select Amount Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="percentage">Percentage</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col sm={6}>
                          <Form.Group className="form-group">
                            <Form.Label>
                              Flight Type
                              <span style={{ color: "#ff0000" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="service_type"
                              value={serviceType}
                              onChange={handleServiceTypeChange}
                              required
                            >
                              <option value="">Select Flight Type</option>
                              <option value="domestic">Domestic</option>
                              <option value="international">
                                International
                              </option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <Form.Group className="form-group float-right">
                            <Button className="btn btn-theme" type="submit">
                              <FaSave /> Save
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default NewMarkup;
