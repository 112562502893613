import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { TextField, Container, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { countries } from "../../../../Countries";

const TravellerInformation = ({
  flight,
  handleInputChange,
  handleInputChange1,
  handleInputChange2,
  handleExpiryDateChange,
  handleExpiryDateChange1,
  handleExpiryDateChange2,
  handleDOBChange,
  handleDOBChange1,
  handleDOBChange2,
  isinternational,
  formData,
  childData,
  infant,
}) => {
  const formatDateToYYYYMMDD = (date) => {
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`; // Return the date in YYYY-MM-DD format for the input
  };
  return (
    <div className="traveller_info">
      <h4>Traveller Information</h4>

      {flight && (
        <Row>
          <React.Fragment>
            {[
              ...Array(
                flight[0].PexFareDetails[0] &&
                  flight[0].PexFareDetails[0].TotPax
              ),
            ].map((_, index) => (
              <React.Fragment key={index}>
                <Col sm={12} className="passport_field">
                  <Row style={{ marginBottom: "5px" }}>
                    <Col sm={2} className="form_field_col2">
                      <div className="contact_label cus_label">
                        <Form.Label>Adults {index + 1}</Form.Label>
                      </div>
                    </Col>
                    <Col sm={10} className="form_field_col10">
                      <div className="form_field form_select_field">
                        <div className="cus_field_input cus_select_title">
                          <Form.Control
                            as="select"
                            name={`title${index}`}
                            className="title form-control commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          >
                            <option>Title</option>
                            <option value="Mr">Mr.</option>
                            <option value="Ms">Ms.</option>
                            <option value="Mrs">Mrs.</option>
                          </Form.Control>
                        </div>
                        <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">
                            Title & First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`firstName${index}`}
                            placeholder="First Name"
                            className="form-control firstname commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form_field">
                        <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`lastName${index}`}
                            placeholder="Last Name"
                            className="form-control firstname commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="date_field">
                        <div
                          className="cus_field_input"
                          style={{ marginTop: "20px" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Date of Birth"
                              name={`date${index}`}
                              value={
                                formData[index]?.date
                                  ? dayjs(formData[index]?.date)
                                  : null
                              }
                              onChange={(newValue) => {
                                const syntheticEvent = {
                                  target: {
                                    name: `date${index}`,
                                    value: newValue?.format("YYYY-MM-DD") || "",
                                  },
                                };
                                handleInputChange(index, syntheticEvent);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="Date of Birth"
                                  autoComplete="off"
                                  style={{ height: "20px", color: "#000" }}
                                  className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      {/* <div className="date_field">
                        <div className="cus_field_input">
                          <Form.Label className="travellers-service-form-controll">
                            Date Of Birth
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name={`date${index}`}
                            style={{ height: "auto" }}
                            className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                            onChange={(e) => handleDOBChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div> */}
                    </Col>
                  </Row>

                  {/* {isinternational && ( */}
                  <Row>
                    <Col sm={2} className="form_field_col2">
                      <div className="contact_label cus_label">
                        <Form.Label>Passport Detail</Form.Label>
                      </div>
                    </Col>
                    <Col sm={10} className="form_field_col10 ">
                      <div className="form_field">
                        <div className="cus_field_input cus_name_field">
                          <Form.Label className="travellers-service-form-controll">
                            Passport Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name={`passport${index}`}
                            placeholder="Passport No."
                            className="form-control firstname commoninputreset"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      {/* <div
                        className="date_field"
                        style={{ marginRight: "15px" }}
                      >
                        <div className="cus_field_input">
                          <Form.Control
                            type="date"
                            name={`issuedate${index}`}
                            style={{ height: "auto" }}
                            placeholder="Issue Date"
                            className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          />
                        </div>
                      </div> */}
                      <div className="date_field">
                        <div
                          className="cus_field_input"
                          style={{ marginTop: "20px" }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Expiry Date"
                              name={`expirydate${index}`} // Dynamically set the name
                              value={
                                formData[index]?.expirydate
                                  ? dayjs(
                                      formData[index]?.expirydate,
                                      "DD-MM-YYYY"
                                    )
                                  : null // Convert string to dayjs object with the correct format
                              }
                              onChange={(newValue) => {
                                // Create a synthetic event to match handleInputChange's expected input
                                const syntheticEvent = {
                                  target: {
                                    name: `expirydate${index}`,
                                    value: newValue?.format("DD-MM-YYYY") || "", // Format as DD-MM-YYYY
                                  },
                                };
                                handleInputChange(index, syntheticEvent);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="Expiry Date"
                                  autoComplete="off"
                                  style={{ height: "auto" }}
                                  inputProps={{
                                    ...params.inputProps,
                                    min: new Date().toISOString().split("T")[0], // Set min to today's date
                                  }}
                                  className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="date_field">
                        <div className="cus_field_input ">
                          <Form.Label
                            className="travellers-service-form-controll"
                            style={{ marginLeft: "10px" }}
                          >
                            Select Country
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name={`nationality${index}`}
                            className="title form-control commoninputreset selection_country_Ccode_Travell"
                            onChange={(e) => handleInputChange(index, e)}
                            autoComplete="off"
                          >
                            <option value="Nationality">Nationality</option>
                            {countries.map((country, index) => (
                              <option value={country.Code}>
                                {country.Name}
                              </option>
                            ))}
                          </Form.Control>
                        </div>
                      </div>
                      {/* <div className="date_field">
                          <div className="cus_field_input">
                            <Form.Label className="travellers-service-form-controll">
                              Passport Expiry Date
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name={`expirydate${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleExpiryDateChange(index, e)}
                              // value={formData[index]?.expirydate ? formatDateToYYYYMMDD(formData[index]?.expirydate) : ""}  // Convert DD-MM-YYYY to YYYY-MM-DD for the input
                              autoComplete="off"
                            />
                          </div>
                        </div> */}
                    </Col>
                  </Row>
                  {/* )} */}
                </Col>
              </React.Fragment>
            ))}
          </React.Fragment>

          {flight[0].PexFareDetails[1] && (
            <React.Fragment>
              {[...Array(flight[0].PexFareDetails[1].TotPax)].map(
                (_, index) => (
                  <React.Fragment key={index}>
                    <Col sm={12} className="passport_field">
                      <Row style={{ marginBottom: "5px" }}>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Child {index + 1}</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10">
                          <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Title & First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`lastName${index}`}
                                placeholder="Last Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="date_field">
                            <div
                              className="cus_field_input"
                              style={{ marginTop: "20px" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Date of Birth"
                                  name={`date${index}`}
                                  value={
                                    childData[index]?.date
                                      ? dayjs(childData[index]?.date)
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const syntheticEvent = {
                                      target: {
                                        name: `date${index}`,
                                        value:
                                          newValue?.format("YYYY-MM-DD") || "",
                                      },
                                    };
                                    handleInputChange1(index, syntheticEvent);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      placeholder="Date of Birth"
                                      autoComplete="off"
                                      style={{ height: "20px", color: "#000" }}
                                      className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          {/* <div className="date_field">
                          <div className="cus_field_input">
                            <Form.Label className="travellers-service-form-controll">
                              Date Of Birth
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name={`date${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleDOBChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div> */}
                        </Col>
                      </Row>

                      {/* {isinternational && ( */}
                      <Row>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Passport Detail</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10 ">
                          {/* <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Passport Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`passport${index}`}
                                placeholder="Passport No."
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          {/* <div
                          className="date_field"
                          style={{ marginRight: "15px" }}
                        >
                          <div className="cus_field_input">
                            <Form.Control
                              type="date"
                              name={`issuedate${index}`}
                              style={{ height: "auto" }}
                              placeholder="Issue Date"
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              onChange={(e) => handleInputChange1(index, e)}
                              autoComplete="off"
                            />
                          </div>
                        </div> */}

                          <div className="date_field">
                            <div
                              className="cus_field_input"
                              style={{ marginTop: "20px" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Expiry Date"
                                  name={`expirydate${index}`} // Dynamically set the name
                                  value={
                                    childData[index]?.expirydate
                                      ? dayjs(
                                          childData[index]?.expirydate,
                                          "DD-MM-YYYY"
                                        )
                                      : null // Convert string to dayjs object with the correct format
                                  }
                                  onChange={(newValue) => {
                                    // Create a synthetic event to match handleInputChange's expected input
                                    const syntheticEvent = {
                                      target: {
                                        name: `expirydate${index}`,
                                        value:
                                          newValue?.format("DD-MM-YYYY") || "", // Format as DD-MM-YYYY
                                      },
                                    };
                                    handleInputChange1(index, syntheticEvent);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      placeholder="Expiry Date"
                                      autoComplete="off"
                                      style={{ height: "auto" }}
                                      inputProps={{
                                        ...params.inputProps,
                                        min: new Date()
                                          .toISOString()
                                          .split("T")[0], // Set min to today's date
                                      }}
                                      className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="date_field">
                            <div className="cus_field_input ">
                              <Form.Label
                                className="travellers-service-form-controll"
                                style={{ marginLeft: "10px" }}
                              >
                                Select Country
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name={`nationality${index}`}
                                className="title form-control commoninputreset selection_country_Ccode_Travell"
                                onChange={(e) => handleInputChange1(index, e)}
                                autoComplete="off"
                              >
                                <option value="Nationality">Nationality</option>
                                {countries.map((country, index) => (
                                  <option value={country.Code}>
                                    {country.Name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>
                          </div>
                          {/* <div className="date_field">
                            <div className="cus_field_input">
                              <Form.Label className="travellers-service-form-controll">
                                Passport Expiry Date
                              </Form.Label>
                              <Form.Control
                                type="date"
                                name={`expirydate${index}`}
                                style={{ height: "auto" }}
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) =>
                                  handleExpiryDateChange1(index, e)
                                }
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                        </Col>
                      </Row>
                      {/*  )} */}
                    </Col>
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          )}

          {flight[0].PexFareDetails[2] && (
            <React.Fragment>
              {[...Array(flight[0].PexFareDetails[2].TotPax)].map(
                (_, index) => (
                  <React.Fragment key={index}>
                    <Col sm={12} className="passport_field">
                      <Row style={{ marginBottom: "5px" }}>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Infant {index + 1}</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10">
                          <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Title & First Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Last Name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`lastName${index}`}
                                placeholder="Last Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          {/* <div className="date_field">
                          <div className="cus_field_input">
                            <Form.Label className="travellers-service-form-controll">
                              Date Of Birth
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name={`date${index}`}
                              style={{ height: "auto" }}
                              className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                              autoComplete="off"
                              onChange={(e) => handleDOBChange2(index, e)}
                            />
                          </div>
                        </div> */}
                          <div className="date_field">
                            <div
                              className="cus_field_input"
                              style={{ marginTop: "20px" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Date of Birth"
                                  name={`date${index}`}
                                  value={
                                    infant[index]?.date
                                      ? dayjs(infant[index]?.date)
                                      : null
                                  }
                                  onChange={(newValue) => {
                                    const syntheticEvent = {
                                      target: {
                                        name: `date${index}`,
                                        value:
                                          newValue?.format("YYYY-MM-DD") || "",
                                      },
                                    };
                                    handleInputChange2(index, syntheticEvent);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      placeholder="Date of Birth"
                                      autoComplete="off"
                                      style={{ height: "20px", color: "#000" }}
                                      className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* {isinternational && ( */}
                      <Row>
                        <Col sm={2} className="form_field_col2">
                          <div className="contact_label cus_label">
                            <Form.Label>Passport Detail</Form.Label>
                          </div>
                        </Col>
                        <Col sm={10} className="form_field_col10 ">
                          {/* <div className="form_field form_select_field">
                            <div className="cus_field_input cus_select_title">
                              <Form.Control
                                as="select"
                                name={`title${index}`}
                                className="title form-control commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              >
                                <option>Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                              </Form.Control>
                            </div>
                            <div className="cus_field_input cus_name_field">
                              <Form.Control
                                type="text"
                                name={`firstName${index}`}
                                placeholder="First Name"
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                          <div className="form_field">
                            <div className="cus_field_input cus_name_field">
                              <Form.Label className="travellers-service-form-controll">
                                Passport Number
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name={`passport${index}`}
                                placeholder="Passport No."
                                className="form-control firstname commoninputreset"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div>

                          {/* <div
                            className="date_field"
                            style={{ marginRight: "15px" }}
                          >
                            <div className="cus_field_input">
                              <Form.Control
                                type="date"
                                name={`issuedate${index}`}
                                style={{ height: "auto" }}
                                placeholder="Issue Date"
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                          <div className="date_field">
                            <div
                              className="cus_field_input"
                              style={{ marginTop: "20px" }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Expiry Date"
                                  name={`expirydate${index}`} // Dynamically set the name
                                  value={
                                    infant[index]?.expirydate
                                      ? dayjs(
                                          infant[index]?.expirydate,
                                          "DD-MM-YYYY"
                                        )
                                      : null // Convert string to dayjs object with the correct format
                                  }
                                  onChange={(newValue) => {
                                    // Create a synthetic event to match handleInputChange's expected input
                                    const syntheticEvent = {
                                      target: {
                                        name: `expirydate${index}`,
                                        value:
                                          newValue?.format("DD-MM-YYYY") || "", // Format as DD-MM-YYYY
                                      },
                                    };
                                    handleInputChange2(index, syntheticEvent);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      placeholder="Expiry Date"
                                      autoComplete="off"
                                      style={{ height: "auto" }}
                                      inputProps={{
                                        ...params.inputProps,
                                        min: new Date()
                                          .toISOString()
                                          .split("T")[0], // Set min to today's date
                                      }}
                                      className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </div>
                          <div className="date_field">
                            <div className="cus_field_input ">
                              <Form.Label
                                className="travellers-service-form-controll"
                                style={{ marginLeft: "10px" }}
                              >
                                Select Country
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name={`nationality${index}`}
                                className="title form-control commoninputreset selection_country_Ccode_Travell"
                                onChange={(e) => handleInputChange2(index, e)}
                                autoComplete="off"
                              >
                                <option value="Nationality">Nationality</option>
                                {countries.map((country, index) => (
                                  <option value={country.Code}>
                                    {country.Name}
                                  </option>
                                ))}
                              </Form.Control>
                            </div>
                          </div>

                          {/* <div className="date_field">
                            <div className="cus_field_input">
                              <Form.Label className="travellers-service-form-controll">
                                Passport Expiry Date
                              </Form.Label>
                              <Form.Control
                                type="date"
                                name={`expirydate${index}`}
                                style={{ height: "auto" }}
                                className="commoninputreset form-control datepicker-infant-time-start hasDatepicker"
                                onChange={(e) =>
                                  handleExpiryDateChange2(index, e)
                                }
                                autoComplete="off"
                              />
                            </div>
                          </div> */}
                        </Col>
                      </Row>
                      {/* )} */}
                    </Col>
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          )}
        </Row>
      )}
      <div
        className="alert-wrapper makeFlex appendBottom12 appendTop12 travellerSectionAlert"
        style={{ backgroundColor: "#e2cff966", color: "#fff" }}
      >
        <div className="makeFlex flexOne column">
          <p className="darkText fontSize12 boldFont">
            <span className="alert-text">
              <b>Important: </b>Please ensure that the names of the passengers
              on the travel documents are the same as on their government-issued
              identity proof.
              <br />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TravellerInformation;
