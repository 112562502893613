import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import DashboardNavbar from "./Dashboard/Home/DashboardNavbar";
import HeroNavbar from "./Components/Home/Navbar/HeroNavbar";
import Home from "./Components/Home/HomePages/Home";
import RegisterationForm from "./Components/Home/HomePages/RegisterationForm";
import Footer from "./Components/Home/Footer/Footer";
import HomeDash from "./Dashboard/Home/HomeDash";
import Protected from "./Components/Protected/Protected";
import OneWay from "./Dashboard/Flight/OneWay/OneWay";
import Wallet from "./Dashboard/Home/Wallet";
import AboutUs from "./Components/Home/AboutUs/AboutUs";
import TermCondition from "./Components/Home/TermCondition/TermCondition";
import PrivacyPolicy from "./Components/Home/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./Components/Home/Contact/ContactUs";
import Dashboards from "./Dashboard/Home/Dashboards";
import { useEffect } from "react";
import { getCityData } from "./redux/services/operations/auth";
import FlightDetail from "./Dashboard/FlightDetails/FlightDetail";
import RoundTrips from "./Dashboard/Flight/RoundTrip/RoundTrips";
import Profile from "./Dashboard/profile/Profile";
import Timer from "./Timer";
import TicketConfirmation from "./TicketConfirmation";
import NotFound from "./Components/Home/NotFounds/NotFound";
import FlightBookingTicket from "./Dashboard/TicketBooking/BookingTicket";
import RandomIdGenerator from "./Demo";
// import FlightInternational from './Dashboard/Flight/FlightInternational/FlightInternational'
// import FlightDetail from './Dashboard/Flight/FlightDetail/FlightDetail';
// import { FlightList } from './Dashboard/Flight/FlightList/FlightList';
import FlightInternational from "./Dashboard/Flight/FlightInternational/FlightInternational/FlightInternational";
import FlightInternationalDetail from "./Dashboard/Flight/FlightInternational/FlightInternational/FlightInternationalDetail";
import FlightBookingPage from "./Dashboard/FlightBooking/FlightBookingPage";
import FlightBookingDetail from "./Dashboard/FlightBooking/FlightBookingDetail";
import LedgerReport from "./Dashboard/bookingreports/LedgerReport";
import ProfitLoss from "./Dashboard/bookingreports/ProfitLoss";
import DailySalesReport from "./Dashboard/bookingreports/DailySalesReport";
import Invoice from "./Dashboard/FlightBooking/Invoice";
import LedgerReports from "./Dashboard/bookingreports/LedgerOriginal";
import MarkupTable from "./Dashboard/markup/MarkupTable";
import NewMarkup from "./Dashboard/markup/NewMarkup";
import EditMarkup from "./Dashboard/markup/EditMarkup";
import FlightBookingTickets from "./Dashboard/TicketBooking/BookingTickets";
import TicketData from "./Dashboard/TicketBooking/TicketData";
import TicketBooking from "./Dashboard/FlightBooking/TicketBooking";
import PrintableComponent from "./Demo";
import ForgetPassword from "./Components/Home/HomePages/ForgetPassword";

function App() {
  useEffect(() => {
    getCityData();
  });
  const location = useLocation();
  const hideNavbarFooter = location.pathname.startsWith("/agents/password/reset");
  return (
    <>
     {!hideNavbarFooter && <Navbar />}
      <Routes>
        {/* <Route path="/demo" element={<RandomIdGenerator />} /> */}
        <Route path="/demo" element={<PrintableComponent/>}/>
        <Route path="/ticket" element={<TicketConfirmation />} />
        <Route path="/" element={<Home />} />
        <Route path="/agent-registration" element={<RegisterationForm />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-conditions" element={<TermCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/ticket" element={<TicketConfirmation />} />
        <Route
          path="/agent/bookings/transaction-log"
          element={
            <Protected>
              <LedgerReport />
            </Protected>
          }
        />
        <Route
          path="/agent/bookings/ledger-report"
          element={
            <Protected>
              <LedgerReports />
            </Protected>
          }
        />
        <Route exact path="/agent/profitloss" element={<ProfitLoss />} />
        <Route exact path="/invoice" element={<Invoice />} />
        <Route
          exact
          path="/agent/report/dailysale"
          element={<DailySalesReport />}
        />
        <Route
          path="/agent/bookings/flight"
          element={
            <Protected>
              <FlightBookingPage />
            </Protected>
          }
        />
        <Route
          path="/agent/bookings/flight/details/:id"
          element={
            <Protected>
              <FlightBookingDetail />
            </Protected>
          }
        />
        <Route
          path="/agent/dashboard"
          element={
            <Protected>
              <HomeDash />
            </Protected>
          }
        />
        <Route
          path="/agent/flightList/:data"
          element={
            <Protected>
              <OneWay />
            </Protected>
          }
        />
        <Route
          path="/agent/round/:data"
          element={
            <Protected>
              <RoundTrips />{" "}
            </Protected>
          }
        />
        {/* <Route
            path="/agent/flight-detail/:index/:index2?"
            element={
              <Protected>
                <FlightDetail />
              </Protected>
            }
          /> */}
        <Route
          path="/agent/flight-detail/:index/:index2?"
          element={
            <Protected>
              <FlightDetail />
            </Protected>
          }
        />
        <Route
          path="/agent/flight-details/:index?"
          element={
            <Protected>
              <FlightInternationalDetail />
            </Protected>
          }
        />
        {/* <Route
          path="/agent/flightlists"
          element={
            <Protected>
              <OneWay />
            </Protected>
          }
        /> */}
        <Route
          path="/agent/wallet"
          element={
            <Protected>
              <Wallet />
            </Protected>
          }
        />
        <Route
          path="/agent/international-round/:data"
          element={
            <Protected>
              <FlightInternational />
            </Protected>
          }
        />
        <Route
          path="/agent/profile"
          element={
            <Protected>
              <Profile />
            </Protected>
          }
        />
        <Route
          path="/agent/flight-ticket"
          element={
            <Protected>
              {/* <TicketData/> */}
              <FlightBookingTicket />
            </Protected>
          }
        />
         <Route
          path="/agent/flight-tickets"
          element={
            <Protected>
              <FlightBookingTickets />
            </Protected>
          }
        />
        <Route path="/agent/mark-up" element={<MarkupTable />} />
        <Route path="/agent/add-markup" element={<NewMarkup />} />
        <Route
          path="/agent/edit-markup/:id"
          element={<EditMarkup />}
        />
         <Route
          exact
          path="/agent/bookings/flight/ticket-detail/:id"
          element={<TicketBooking />}
        />
          <Route path='/agents/password/reset/:token?/:email?' element={<ForgetPassword/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!hideNavbarFooter && <Footer />}
    </>
  );
}
function Navbar() {
  const location = useLocation();
  const isAgentRoute =
    location.pathname.includes("/agent/") &&
    !location.pathname.startsWith("/agent/login");

  return isAgentRoute ? <DashboardNavbar /> : <HeroNavbar />;
}
// function Footerr() {
//   const location = useLocation();
//   const isDashboard =
//     location.pathname.includes("/agent/") &&
//     !location.pathname.startsWith("/agent/login");
//   return isDashboard ? <DashFooter /> : <Footer />;
// }

export default App;
